<template>
  <div v-if="isshowgrid">
    <va-card title="Language List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
          <va-button flat small color="red" icon="fa fa-times" @click="deleteLang(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
    <va-modal
      v-model="showRemoveModal"
      title="Remove Language"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteLanguage(removed_row_content)"
      @cancel="cancel()">
    </va-modal>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Language</span>
            <va-input
              v-model.trim="language"
              type="text"
              placeholder="Enter Language"
              :error="!!languageErrors.length"
              :error-messages="languageErrors"
            />
            <span class="va-form-label va-form-required-label">Native</span>
            <va-input
              v-model.trim="native"
              type="text"
              placeholder="Enter Native"
              :error="!!nativeErrors.length"
              :error-messages="nativeErrors"
            />
            <span class="va-form-label va-form-required-label">Short Code</span>
            <va-input
              v-model.trim="short_code"
              type="text"
              placeholder="Enter Short Code"
              :error="!!shortCodeErrors.length"
              :error-messages="shortCodeErrors"
            />
            <span class="va-form-label va-form-required-label">Order</span>
            <va-input
              v-model.trim="orders"
              type="text"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              placeholder="Enter Order"
              :error="!!ordersErrors.length"
              :error-messages="ordersErrors"
            />
            <span v-if="isCreate" class="va-form-label va-form-required-label">Logo</span>
            <span v-if="isUpdate" class="va-form-label">Logo</span>
            <va-file-upload
              type="single"
              dropzone
              v-model="logo_file"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createLanguage()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateLanguage()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'language',
  created () {
    this.getAllLanguage()
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
    // this.$http.get(config.menu.host + '/language').then(response => {
    //   loader.hide()
    //   this.languageData = response.body
    //   this.isshowForm = false
    //   this.isshowgrid = true
    //   this.getAllLanguage()
    // }, error => {
    //   loader.hide()
    //   if (error && error.body) {
    //     Vue.notify({ text: error.body, type: 'error' })
    //   }
    // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      message: '',
      showRemoveModal: false,
      language: '',
      native: '',
      short_code: '',
      orders: '',
      title: '',
      languageData: [],
      logo_file: [],
      languageErrors: [],
      nativeErrors: [],
      shortCodeErrors: [],
      ordersErrors: [],
    }
  },
  computed: {
    Check_upload () {
      if (this.logo_file[0]) {
        return true
      } else {
        Vue.notify({ text: 'please Upload images', type: 'error' })
        return false
      }
    },
    formReady () {
      return !this.languageErrors.length && !this.nativeErrors.length && !this.shortCodeErrors.length && !this.ordersErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'language',
        title: 'Language',
      },
      {
        name: 'native',
        title: 'Native',
      },
      {
        name: 'short_code',
        title: 'Short Code',
      },
      {
        name: 'orders',
        title: 'ORDER',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.languageData)
    },
  },
  methods: {
    getAllLanguage () {
      this.$http.get(config.menu.host + '/language').then(response => {
        let index = 0
        this.languageData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    createLanguage () {
      this.languageErrors = this.language ? [] : ['Language is required']
      this.nativeErrors = this.native ? [] : ['native is required']
      this.shortCodeErrors = this.short_code ? [] : ['Short Code is required']
      this.ordersErrors = this.orders ? [] : ['Order is required']
      if (!this.formReady) {
        return
      }
      if (!this.Check_upload) {
        return
      }
      var payload = new FormData()
      payload.append('logo', this.logo_file[0])
      payload.append('language', this.language)
      payload.append('native', this.native)
      payload.append('short_code', this.short_code)
      payload.append('orders', this.orders)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/language', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateLanguage () {
      var payload = new FormData()
      if (this.logo_file[0]) {
        payload.append('logo', this.logo_file[0])
      }
      payload.append('language', this.language)
      payload.append('native', this.native)
      payload.append('short_code', this.short_code)
      payload.append('orders', this.orders)

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/language/' + this.language_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    deleteLang (rowData) {
      this.message = 'Are you sure to delete ' + rowData.language + ' language ?'
      this.showRemoveModal = true
      this.removed_row_content = rowData
    },
    deleteLanguage (rowData) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + '/language/' + rowData.language_id).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({ text: response.body, type: 'success' })
        }
        this.getAllLanguage()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    edit (row) {
      this.title = 'Update Language'
      this.language_id = row.language_id
      this.language = row.language
      this.short_code = row.short_code
      this.orders = row.orders
      this.native = row.native
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    cancel () {
      this.showRemoveModal = false
    },
    add () {
      this.title = 'Create Language'
      this.language = ''
      this.native = ''
      this.short_code = ''
      this.orders = ''
      this.logo_file = []
      this.languageErrors = []
      this.nativeErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllLanguage()
      this.logo_file = []
      this.shortCodeErrors = []
      this.ordersErrors = []
      this.languageErrors = []
      this.nativeErrors = []
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
